import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import Modal_Popup from '../Modal-Popup';


class Index extends React.Component {
    constructor(props) {
        super(props);
        
        let uriparms_one = '';
        let uriparms_two = '';
        let pathname = props.location.pathname;
        pathname = pathname.split('/');

        uriparms_one = pathname[1];

        if(typeof pathname[2] !== 'undefined') {
         uriparms_two = pathname[2];
        }

		this.state = {
          modalRespond: false,
          uriparms_one:uriparms_one,
            uriparms_two:uriparms_two
		};
		
		this.toggleRespond = this.toggleRespond.bind(this);
		
      }
      
      toggleRespond() {
		//e.preventDefault();
		this.setState(prevState => ({
			modalRespond: !prevState.modalRespond
		}));
	 }

render() {  

  return (
    <div>
    <Header {...this.state}/>
    
    <div className="container"> {/*----container-----*/}  
        <div className="content-section inbox_wrapper">
            <div className="inbox_ttsec">
                <h1 className="title1">Inbox</h1>
                <h2 className="title2">Task</h2>
            </div>
            <div className="inbox_list">
                <div className="inbox_row">
                    <div className="row">
                        <div className="col-md-9 inbox_info">
                            <h3 className="title2">1. Submit your reflection on teaching philosophy</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, voluptatum quam quibusdam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
voluptatum quam quibusdam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus </p>
                        </div>
                        <div className="col-md-3 inbox_action text-right">
                            <Link to="" className="btn btn_blue btn_minwid" onClick={this.toggleRespond} title="Respond">Respond</Link>
                        </div>
                    </div>
                </div>
                <div className="inbox_row">
                    <div className="row">
                        <div className="col-md-9 inbox_info"> 
                            <h3 className="title2">2. Submit your reflection on teaching philosophy</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, voluptatum quam quibusdam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
voluptatum quam quibusdam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus </p>
                        </div>
                        <div className="col-md-3 inbox_action text-right"> 
                            <Link to="" className="btn btn_blue btn_minwid btn_responded_edit" onClick={this.toggleRespond} title="Respond">Responded <i></i></Link>
                        </div>
                    </div>
                </div>
                <div className="inbox_row">
                    <div className="row">
                        <div className="col-md-9 inbox_info">
                            <h3 className="title2">3. Submit your reflection on teaching philosophy</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, voluptatum quam quibusdam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
voluptatum quam quibusdam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus </p>
                        </div>
                        <div className="col-md-3 inbox_action text-right">
                            <Link to="" className="btn btn_blue btn_minwid" onClick={this.toggleRespond} title="Respond">Respond</Link>
                        </div>
                    </div>
                </div>
                <div className="inbox_row">
                    <div className="row">
                        <div className="col-md-9 inbox_info">
                            <h3 className="title2">4. Submit your reflection on teaching philosophy</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, voluptatum quam quibusdam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
voluptatum quam quibusdam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus </p>
                        </div>
                        <div className="col-md-3 inbox_action text-right">
                            <Link to="" className="btn btn_blue btn_minwid" onClick={this.toggleRespond} title="Respond">Respond</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* Popup Respond */}
    <Modal_Popup modal={this.state.modalRespond} toggle={this.toggleRespond} className="popup_sec popup_respond">
        <div className="popup_in">
            <h3 className="title1 text-center">Respond to Task</h3>
            <div className="respond_searchsec">
                <div class="search-filter">
                    <input type="text" placeholder="Type Here..." class="form-control" />
                    <button type="submit" class="search-icon" ></button>
                </div>
                <ul className="respond_searchlist">
                    <li> Title of the reflection will be here <i></i></li>
                    <li> Title of the reflection will be here <i></i></li>
                </ul>
            </div>
        </div>        
        <button type="submit" className="btn btn_blue btn-block btn_respond">Respond</button>
    </Modal_Popup>

    </div>
  );


  }
}

export default Index;
