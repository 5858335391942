import React from 'react';
import Masonry from 'react-masonry-css';
import $ from "jquery";
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import {Row, Col } from 'reactstrap';
import Select from 'react-select';
import Modal_Popup from '../Modal-Popup';

import gridImg1 from '../../common/images/grid-img1.png';
import gridImg2 from '../../common/images/grid-img2.png';
import profileBanner from '../../common/images/profile-banner.jpg';
import profile from '../../common/images/profile.png';
import user from '../../common/images/user.png';
import map from '../../common/images/map.jpg';

class Index extends React.Component {
    constructor(props) {
        super(props);
        
        let uriparms_one = '';
        let uriparms_two = '';
        let pathname = props.location.pathname;
        pathname = pathname.split('/');

        uriparms_one = pathname[1];

        if(typeof pathname[2] !== 'undefined') {
         uriparms_two = pathname[2];
        }

        this.state = {
            uriparms_one:uriparms_one,
            uriparms_two:uriparms_two
          };
		
      }

     componentDidMount() {

        var moretext = "Read more";
        var lesstext = "Read less"; 
        var str = $(".txt p").text();
        var len = str.length;
        console.log(len);
        $(".readmore").click(function(){
            if($(this).hasClass("less")) {
                $(this).removeClass("less");
                $(this).parent().removeClass("active");
                $(this).html(moretext);
            } else {
                $(this).addClass("less");
                $(this).parent().addClass('active');
                $(this).html(lesstext);
            }
        });           


 }

render() { 

const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    850: 1
}

  return (
    <div>
    <Header {...this.state}/>
    <div className="container"> {/*----container-----*/}  
    <div className="content-section">  {/*----content-section-----*/}
        <div className="heading-sec"> 
            <div className="heading-top shwcase-head">           
                <h2 className="title1">Showcase</h2>
                <Link to="" className="export-icon">Export</Link>
            </div>
                <div className="category-list">
                    <Link to="" className="btn ctg-btn active" title="Button">Education</Link>                       
                    <Link to="" className="btn ctg-btn" title="Button">Showcase 2</Link>                        
                    <Link to="" className="btn ctg-btn" title="Button">Showcase 3</Link>                      
                    <Link to="" className="btn ctg-btn" title="Button">Showcase 4</Link>                        
                    <Link to="" className="btn plus-btn" title="Button"><i className="plus-icon"></i></Link>                      
                </div> 
        </div>
        <div className="bg_white_box my-profile-sec">                
            <Link to="" className="edit-icon"></Link>
            <Row className="myprofile-row">
                <Col sm="3">  
                    <div className="profile-pic">
                        <img src={profile} alt="my profile" />
                    </div>
                </Col>
                <Col sm="9" className="profile-dtls">
                    <h2 className="title1">My Profile</h2>
                    <form class="profile-form">
                        <div className="form-group">
                            <label>Name</label>
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="John Tan" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Programme</label>
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="Post-Graduate Diploma in Education (July 2020)" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                            </div>
                        </div>
                    </form>  
                </Col>
            </Row>            
        </div>

        <div className="bg_white_box">  {/*----White Box-----*/}  
            <div className="heading-sec"> 
                <Row className="heading-top mrgn_btm">
                    <Col sm="12">  
                        <h2 className="title2 article-title">Add More Articles</h2>
                    </Col>
                    <Col sm="4" className="right">
                    <div className="input_field custom_select">
                            <Select />
                        </div>                       
                    </Col>
                </Row>              
            </div>

            <div className="grid-list-row">  {/*----Grid Row-----*/}    
            <Masonry  breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">           
                <div className="grid-box">               
                <Link to="" className="close-icon">X</Link>
                <div className="grid-img">  
                    <img src={gridImg1} alt="" />
                </div>
                <div className="grid-body">
                    <div className="grid-date">
                        <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                    </div>
                    <div className="grid-text">
                        <h2 className="title2">Title2</h2>
                        <div className="txt">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                            voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>

                            <Link to="" className="readmore">Read more</Link>
                        </div>
                    </div>
                    <div class="share-dtls">
                        <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                        <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                        <div className="like-cmnts">
                            <Link to="" className="like"></Link>
                            <Link to="" className="coments"><span className="count">4</span></Link>
                        </div>
                    </div>
                    <div className="tags">
                        <p>VER1, VER2, VER3 #teaching philosophy
                            <Link to="" className="readmore">...</Link>
                        </p>
                    </div>
                    <div className="review-comment">   
                        <div className="comment-list">
                            <span className="user-img"><img src={user} alt="" /></span>
                            <div className="comments-text">
                                <div className="post_name_date">
                                    <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                                </div>
                                <p>Can you send me more informtion please</p>
                            </div>
                            <Link to="" class="close-icon">x</Link>
                        </div>                    
                        <div className="write-comment">
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                                <span className="user-img"><img src={user} alt="" /></span>
                            </div>
                        </div> 
                            
                        </div>
                    </div>
                </div> 

                <div className="grid-box">               
                <Link to="" className="close-icon">X</Link>
                <div className="grid-img">  
                    <img src={gridImg1} alt="" />
                </div>
                <div className="grid-body">
                    <div className="grid-date">
                        <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                    </div>
                    <div className="grid-text">
                        <h2 className="title2">Title2</h2>
                        <div className="txt">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                            voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                            
                            <Link to="" className="readmore">Read more</Link>
                        </div>
                    </div>
                    <div class="share-dtls">
                        <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                        <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                        <div className="like-cmnts">
                            <Link to="" className="like"></Link>
                            <Link to="" className="coments"><span className="count">4</span></Link>
                        </div>
                    </div>
                    <div className="tags">
                        <p>VER1, VER2, VER3 #teaching philosophy
                            <Link to="" className="readmore">...</Link>
                        </p>
                    </div>
                    <div className="review-comment">   
                        <div className="comment-list">
                            <span className="user-img"><img src={user} alt="" /></span>
                            <div className="comments-text">
                                <div className="post_name_date">
                                    <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                                </div>
                                <p>Can you send me more informtion please</p>
                            </div>
                            <Link to="" class="close-icon">x</Link>
                        </div>                    
                        <div className="write-comment">
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                                <span className="user-img"><img src={user} alt="" /></span>
                            </div>
                        </div> 
                            
                        </div>
                    </div>
                </div> 
                </Masonry> 
            </div> {/*----Grid Row End-----*/}  
            <div className="save_publish">
                <Link to="" className="btn btn_grey save_btn" title="Button">SAVE DRAFT</Link>
                <Link to="" className="btn btn_blue publish_btn" title="Button">PUBLISH</Link>
            </div>
        </div> {/*----White Box End-----*/}  


    </div>  {/*----content-section-----*/}
    </div>  {/*------Container End------*/}
    </div>
  );


  }
}

export default Index;
