import React from 'react';
import Masonry from 'react-masonry-css';

import { Link } from 'react-router-dom';

import {Row, Col } from 'reactstrap';
import Select from 'react-select';

import CKEditor from 'ckeditor4-react';
//import CKEditor from "@ckeditor/ckeditor5-react";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';

  import Geocode from "react-geocode";
 

import Modal_Popup from '../Modal-Popup';

import $ from "jquery";

import Header from '../Layout/Header';

import gridImg1 from '../../common/images/grid-img1.png';
import gridImg2 from '../../common/images/grid-img2.png';
import gridImg3 from '../../common/images/grid-img3.png';
import gridImg4 from '../../common/images/grid-img4.png';
import gridImg5 from '../../common/images/grid-img5.png';
import ico_list from '../../common/images/ico_list.png';
import ico_document from '../../common/images/ico_document.png';
import ico_clipboard from '../../common/images/ico_clipboard.png';
import upload_image from '../../common/images/upload_image.png';
import Google_Drive_icon from '../../common/images/Google-Drive-icon.png';
import user from '../../common/images/user.png';

Geocode.setApiKey("AIzaSyCToaY_S4na2T8C_SV7l4HdHBFwyPcJC3Y");
Geocode.setLanguage("en");

class Index extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
          modalReflection: false,
          uriparms_one:'',
          uriparms_two:'',
          address: '',
          latitude: '',
          longitude: '',
		};
		
        this.toggleReflection = this.toggleReflection.bind(this);
     
        this.getUri();

      }

     componentDidMount() {

            var moretext = "Read more";
            var lesstext = "Read less"; 
            var str = $(".txt p").text();
            var len = str.length;
         
            $(".readmore").click(function(){
                if($(this).hasClass("less")) {
                    $(this).removeClass("less");
                    $(this).parent().removeClass("active");
                    $(this).html(moretext);
                } else {
                    $(this).addClass("less");
                    $(this).parent().addClass('active');
                    $(this).html(lesstext);
                }
            });           

              
     }

     componentDidUpdate(prevProps) {
    
        // Typical usage (don't forget to compare props):
        if (this.props.location.pathname !== prevProps.location.pathname) {

           this.getUri();
        }
    }
    getUri () {
        
        let uriparms_one = '';
        let uriparms_two = '';
        let pathname = this.props.location.pathname;
        pathname = pathname.split('/');

        uriparms_one = pathname[1];

        if(typeof pathname[2] !== 'undefined') {
        uriparms_two = pathname[2];
        }

        this.setState({ uriparms_one:uriparms_one,
            uriparms_two:uriparms_two})

    } 

    toggleReflection() {
		//e.preventDefault();
		this.setState(prevState => ({
			modalReflection: !prevState.modalReflection
        }));
        this.getMyLocation()   
     }

     handleChange = address => {
         console.log(address);
       /* this.setState({ address });*/
      };
     
      handleSelect = address => {
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
              this.setState({ address });
              console.log('Success', latLng,'address',address)})
          .catch(error => console.error('Error', error));
      };

      getMyLocation() {
        const location = window.navigator && window.navigator.geolocation
        
        if (location) {
          location.getCurrentPosition((position) => {
            this.setState({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })

            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                response => {
                  const address = response.results[0].formatted_address;
                  console.log(address);
                  this.setState({ address });
                },
                error => {
                  console.error(error);
                }
              );

console.log(position);

          }, (error) => {
            console.log(error);
            this.setState({ latitude: '', longitude: '' })
          })
        }
    
      }
  
render() {  

    const breakpointColumnsObj = {
        default: 2,
        1200: 2,
        850: 1
    }
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

  return (
    <div>
    <Header {...this.state}/>
    <div className="container"> {/*----Containner-----*/}     
    <div className="content-section"> {/*----content-section-----*/}
        <div className="bg_white_box">
            <div className="heading-sec"> 
                <Row className="heading-top">
                    <Col sm="12" md="8">  
                        <h2 className="title1">Reflections{(this.state.uriparms_two !== '')?' - '+this.state.uriparms_two:''}</h2>
                    </Col>
                    <Col sm="12" md="4" className="right">
                        <Link to="" onClick={this.toggleReflection} className="btn btn_blue reflex_btn" title="Write reflection">Write reflection</Link>  
                    </Col>
                </Row> 
                <div className="filter-sec">
                <form className="form_sec">
                    <div className="form-group">
                     <Row>
                        <Col lg={2} md={4} sm={6}> 
                        <div className="input_field custom_select">
                            <Select
                                searchable='true' 
                                placeholder='Courses'
                                options={options}
                            />
                        </div>
                        </Col>
                        <Col lg={2} md={4} sm={6}> 
                        <div className="input_field custom_select">
                            <Select
                                searchable='true' 
                                placeholder='Events'
                                options={options}
                            />
                        </div>
                        </Col>
                        <Col lg={2} md={4} sm={6}> 
                        <div className="input_field custom_select">
                            <Select
                                searchable='true' 
                                placeholder='Values'
                                options={options}
                            />
                        </div>
                        </Col>
                        <Col lg={2} md={4} sm={6}> 
                        <div className="input_field custom_select">
                            <Select
                                searchable='true' 
                                placeholder=''
                                options={options}
                            />
                        </div>
                        </Col>
                        <Col lg={4} md={4} sm={12}> 
                        <div className="input_field search-filter">
                            <input type="text" placeholder="Search...." className="form-control" />
                            <i className="search-icon"></i>
                        </div>    
                        </Col>                 
                     </Row>
                     </div>
                   </form>  
                </div>
            </div>

            <div className="grid-list-row">  {/*----Grid Row-----*/}    

            <Masonry  breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                <div className="grid-box">         
                <div className="grid-img">  
                    <img src={gridImg1} alt="" />
                </div>
                <div className="grid-body">
                    <div className="grid-date">
                        <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                    </div>
                    <div className="grid-text">
                        <h2 className="title2">MY TEACHING PHILOSOPHY</h2>
                        <div className="txt">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                            voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor 
                            sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, voluptatum quam quibusdam.
                             Lorem ipsum dolor sit amet, consectetur adipisicing elit </p>

                            <Link to="" className="readmore">Read more</Link>
                        </div>
                    </div>
                    <div className="share-dtls">
                        <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                        <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                        <div className="like-cmnts">
                            <Link to="" className="like"></Link>
                            <Link to="" className="coments"><span className="count">4</span></Link>
                        </div>
                    </div>
                    <div className="tags">
                        <p>VER1, VER2, VER3 #teaching philosophy
                            <Link to="" className="readmore">...</Link>
                        </p>
                    </div>
                    <div className="review-comment">   
                        <div className="comment-list">
                            <span className="user-img"><img src={user} alt="" /></span>
                            <div className="comments-text">
                                <div className="post_name_date">
                                    <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                                </div>
                                <p>Can you send me more informtion please</p>
                            </div>
                            <Link to="" className="close-icon">x</Link>
                        </div>                    
                        <div className="write-comment">
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                                <span className="user-img"><img src={user} alt="" /></span>
                            </div>
                        </div> 
                            
                        </div>
                    </div>
                </div> 

                <div className="grid-box">              
                <div className="grid-img">  
                    <img src={gridImg2} alt="" />
                </div>
                <div className="grid-body">
                    <div className="grid-date">
                        <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                    </div>
                    <div className="grid-text">
                        <h2 className="title2">MY TEACHING PHILOSOPHY</h2>
                        <div className="txt">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                            voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                            
                            <Link to="" className="readmore">Read more</Link>
                        </div>
                    </div>
                    <div className="share-dtls">
                        <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                        <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                        <div className="like-cmnts">
                            <Link to="" className="like"></Link>
                            <Link to="" className="coments"><span className="count">4</span></Link>
                        </div>
                    </div>
                    <div className="tags">
                        <p>VER1, VER2, VER3 #teaching philosophy
                            <Link to="" className="readmore">...</Link>
                        </p>
                    </div>
                    <div className="review-comment">   
                        <div className="comment-list">
                            <span className="user-img"><img src={user} alt="" /></span>
                            <div className="comments-text">
                                <div className="post_name_date">
                                    <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                                </div>
                                <p>Can you send me more informtion please</p>
                            </div>
                            <Link to="" className="close-icon">x</Link>
                        </div>                    
                        <div className="write-comment">
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                                <span className="user-img"><img src={user} alt="" /></span>
                            </div>
                        </div> 
                            
                        </div>
                    </div>
                </div> 

                <div className="grid-box">             
                <div className="grid-img">  
                    <img src={gridImg3} alt="" />
                </div>
                <div className="grid-body">
                    <div className="grid-date">
                        <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                    </div>
                    <div className="grid-text">
                        <h2 className="title2">MY TEACHING PHILOSOPHY</h2>
                        <div className="txt">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                            voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor 
                            sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, voluptatum quam quibusdam.
                             Lorem ipsum dolor sit amet, consectetur adipisicing elit </p>

                            <Link to="" className="readmore">Read more</Link>
                        </div>
                    </div>
                    <div className="share-dtls">
                        <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                        <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                        <div className="like-cmnts">
                            <Link to="" className="like"></Link>
                            <Link to="" className="coments"><span className="count">4</span></Link>
                        </div>
                    </div>
                    <div className="tags">
                        <p>VER1, VER2, VER3 #teaching philosophy
                            <Link to="" className="readmore">...</Link>
                        </p>
                    </div>
                    <div className="review-comment">   
                        <div className="comment-list">
                            <span className="user-img"><img src={user} alt="" /></span>
                            <div className="comments-text">
                                <div className="post_name_date">
                                    <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                                </div>
                                <p>Can you send me more informtion please</p>
                            </div>
                            <Link to="" className="close-icon">x</Link>
                        </div>                    
                        <div className="write-comment">
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                                <span className="user-img"><img src={user} alt="" /></span>
                            </div>
                        </div> 
                            
                        </div>
                    </div>
                </div> 

                <div className="grid-box">               
                <div className="grid-img">  
                    <img src={gridImg2} alt="" />
                </div>
                <div className="grid-body">
                    <div className="grid-date">
                        <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                    </div>
                    <div className="grid-text">
                        <h2 className="title2">MY TEACHING PHILOSOPHY</h2>
                        <div className="txt">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                            voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                            
                            <Link to="" className="readmore">Read more</Link>
                        </div>
                    </div>
                    <div className="share-dtls">
                        <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                        <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                        <div className="like-cmnts">
                            <Link to="" className="like"></Link>
                            <Link to="" className="coments"><span className="count">4</span></Link>
                        </div>
                    </div>
                    <div className="tags">
                        <p>VER1, VER2, VER3 #teaching philosophy
                            <Link to="" className="readmore">...</Link>
                        </p>
                    </div>
                    <div className="review-comment">   
                        <div className="comment-list">
                            <span className="user-img"><img src={user} alt="" /></span>
                            <div className="comments-text">
                                <div className="post_name_date">
                                    <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                                </div>
                                <p>Can you send me more informtion please</p>
                            </div>
                            <Link to="" className="close-icon">x</Link>
                        </div>                    
                        <div className="write-comment">
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                                <span className="user-img"><img src={user} alt="" /></span>
                            </div>
                        </div> 
                            
                        </div>
                    </div>
                </div> 
                <div className="grid-box">               
                <div className="grid-img">  
                    <img src={gridImg4} alt="" />
                </div>
                <div className="grid-body">
                    <div className="grid-date">
                        <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                    </div>
                    <div className="grid-text">
                        <h2 className="title2">MY TEACHING PHILOSOPHY</h2>
                        <div className="txt">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                            voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                            
                            <Link to="" className="readmore">Read more</Link>
                        </div>
                    </div>
                    <div className="share-dtls">
                        <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                        <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                        <div className="like-cmnts">
                            <Link to="" className="like"></Link>
                            <Link to="" className="coments"><span className="count">4</span></Link>
                        </div>
                    </div>
                    <div className="tags">
                        <p>VER1, VER2, VER3 #teaching philosophy
                            <Link to="" className="readmore">...</Link>
                        </p>
                    </div>
                    <div className="review-comment">   
                        <div className="comment-list">
                            <span className="user-img"><img src={user} alt="" /></span>
                            <div className="comments-text">
                                <div className="post_name_date">
                                    <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                                </div>
                                <p>Can you send me more informtion please</p>
                            </div>
                            <Link to="" className="close-icon">x</Link>
                        </div>                    
                        <div className="write-comment">
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                                <span className="user-img"><img src={user} alt="" /></span>
                            </div>
                        </div> 
                            
                        </div>
                    </div>
                </div>
                <div className="grid-box">               
                <div className="grid-img">  
                    <img src={gridImg5} alt="" />
                </div>
                <div className="grid-body">
                    <div className="grid-date">
                        <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                    </div>
                    <div className="grid-text">
                        <h2 className="title2">MY TEACHING PHILOSOPHY</h2>
                        <div className="txt">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                            voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                            
                            <Link to="" className="readmore">Read more</Link>
                        </div>
                    </div>
                    <div className="share-dtls">
                        <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                        <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                        <div className="like-cmnts">
                            <Link to="" className="like"></Link>
                            <Link to="" className="coments"><span className="count">4</span></Link>
                        </div>
                    </div>
                    <div className="tags">
                        <p>VER1, VER2, VER3 #teaching philosophy
                            <Link to="" className="readmore">...</Link>
                        </p>
                    </div>
                    <div className="review-comment">   
                        <div className="comment-list">
                            <span className="user-img"><img src={user} alt="" /></span>
                            <div className="comments-text">
                                <div className="post_name_date">
                                    <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                                </div>
                                <p>Can you send me more informtion please</p>
                            </div>
                            <Link to="" className="close-icon">x</Link>
                        </div>                    
                        <div className="write-comment">
                            <div className="input_field">
                                <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                                <span className="user-img"><img src={user} alt="" /></span>
                            </div>
                        </div> 
                            
                        </div>
                    </div>
                </div>
            </Masonry>
        </div> {/*----Grid Row End-----*/}  
        </div>
            
            {/* Popup Reflection */}
            <Modal_Popup modal={this.state.modalReflection} toggle={this.toggleReflection} className="popup_sec popup_reflection">
            <div className="popup_in">
                <h3 className="title2">Write Your Reflection</h3>
                <form className="form_sec">
                    <div className="keytag_list share_keytag_list">
                        <label>Share With:</label>
                        <div className="keytag_item">
                            <span>VER1</span>
                            <i>x</i>
                        </div>
                        <div className="keytag_item">
                            <span>VER2</span>
                            <i>x</i>
                        </div>
                        <div className="keytag_item">
                            <span>VER3 #teaching philosophy</span>
                            <i>x</i>
                        </div>                                                          
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-6 col-12">
                                <div className="input_field input_greyfield input_field_igroup">
                                    <div className="custom_select">
                                        <i className="input_filed_ico">
                                            <img src={ico_list} alt="" />
                                        </i>
                                        <Select
                                            //value={this.state.productSelectedOption }
                                            searchable='true'
                                            //onChange={this.handleChangeone}
                                            placeholder='Courses'
                                            options={options}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-12">
                                <div className="input_field input_greyfield">
                                   {/*} <input type="text" className="form-control" value="75 Ayer Rajah Crescent, Singapore 139953" />
{*/}
                                    <PlacesAutocomplete
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                    onSelect={this.handleSelect}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'form-control location-search-input',
                                        })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    )}
                                </PlacesAutocomplete>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-6 col-12">
                                <div className="input_field input_greyfield input_field_igroup">
                                    <div className="custom_select">
                                        <i className="input_filed_ico">
                                            <img src={ico_document} alt="" />
                                        </i>
                                        <Select
                                            //value={this.state.productSelectedOption }
                                            searchable='true'
                                            //onChange={this.handleChangeone}
                                            placeholder='Values'
                                            options={options}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-12">
                                <div className="row">
                                    <div className="col-sm-6 col-12">
                                        <div className="input_field input_greyfield">
                                            <div className="custom_select">
                                                <Select 
                                                    searchable='true'
                                                    placeholder='Share at'
                                                    options={options} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12">
                                        <div className="input_field input_greyfield">
                                            <div className="custom_select">
                                                <Select 
                                                    searchable='true'
                                                    placeholder='Share with '
                                                    options={options} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-6 col-12">
                                <div className="input_field input_greyfield input_field_igroup">
                                    <div className="custom_select">
                                        <i className="input_filed_ico">
                                            <img src={ico_clipboard} alt="" />
                                        </i>
                                        <Select
                                            //value={this.state.productSelectedOption }
                                            searchable='true'
                                            //onChange={this.handleChangeone}
                                            placeholder='Events'
                                            options={options}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-12">
                                <div className="input_field input_greyfield hashtag_field">
                                    <input type="text" className="form-control" value="VER1, VER2, VER3 #teaching philosophy" />
                                </div>
                                <div className="keytag_list">
                                    <div className="keytag_item">
                                        <span>VER1</span>
                                        <i>x</i>
                                    </div>
                                    <div className="keytag_item">
                                        <span>VER2</span>
                                        <i>x</i>
                                    </div>
                                    <div className="keytag_item">
                                        <span>VER3 #teaching philosophy</span>
                                        <i>x</i>
                                    </div>                                                          
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ktxteditor_wrapper">
                        <div className="form-group from_refttimg_group">
                            <div className="row">
                                <div className="col-md-8 col-sm-6 col-12">
                                    <div className="input_field">
                                        <input type="text" className="form-control" placeholder="Enter Title of the the reflection..." />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <div className="input_field inputref_imgfield">
                                        <div className="row">
                                            <div className="col-md-8 col-7">
                                                <label>Upload featured image</label>
                                            </div>
                                            <div className="col-md-4 col-5">
                                                <div className="choose_file">
                                                    <input type="file" className="form-control" />
                                                    <span>Browse</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>       
                        <div className="ktxteditor_sec">
                            <div className="ktxtbrowse_top">
                                <label>Photo/Video</label>
                                <ul className="ktxtbrowse_list">
                                    <li>
                                        <Link to="" title="Upload">
                                            <i>
                                                <img src={upload_image} />
                                            </i>
                                            Upload
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="" title="Google Drive">
                                            <i>
                                                <img src={Google_Drive_icon} />
                                            </i>
                                            Google Drive
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        <CKEditor 
                        data="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" 
                        type="classic"
                        /> 

                 {/*}  <CKEditor
                  editor={ ClassicEditor }  
                  data="<p>Hello, csharp corner!</p><br/><br/> <b>This is demo for ckeditor 5 with React</br>"  
                  onInit={ editor => {  
                      // You can store the "editor" and use when it is needed.  
                      console.log( 'Editor is ready to use!', editor );  
                  } }  
                  onChange={ ( event, editor ) => {  
                      const data = editor.getData();  
                      console.log( { event, editor, data } );  
                  } }  
                  onBlur={ ( event, editor ) => {  
                      console.log( 'Blur.', editor );  
                  } }  
                  onFocus={ ( event, editor ) => {  
                      console.log( 'Focus.', editor );  
                  } } 
                 
                />{*/}

                        </div>                         
                    </div>
                    <div className="btn_sec text-right">
                        <button type="submit" className="btn btn_blue btn_minwid">SAVE</button>
                    </div>
                </form>
            </div>
            </Modal_Popup>



      </div> {/*----content-section End-----*/}
        </div> {/*----Container End-----*/}
        </div>
  );


  }
}

export default Index;
