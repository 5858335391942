import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Login from './components/Login';

import Reflection from './components/Reflection';
import Profile from './components/Profile';
import Showcase from './components/Showcase';
import Inbox from './components/Inbox';

import News from './components/News/News';
import Style from './components/Style';

const NoMatch = ({ location }) => (
	<div>
		<div className="pgnot_found">
			<h1 className="title1">4<span>0</span>4</h1>
			<p>The page you requested</p>
			<a href="/" className="btn btn_blue btn_minwid" title="Home">Home</a>
		</div>	
	</div>
  )

class App extends Component {

componentDidMount(){
  
}

render(){
 	return (
    <Router>
			<Switch>
        <Route exact path='/' component={Reflection} />
        <Route path='/login' component={Login} />

        <Route path="/reflection/:reflectionType" component={Reflection} />
				<Route path="/reflection" component={Reflection} />

        <Route path="/profile" component={Profile} />
        <Route path="/showcase" component={Showcase} />
        <Route path="/inbox" component={Inbox} />

        <Route path="/style" component={Style} />
        <Route path="/news" component={News} />

				<Route component={NoMatch} />
			</Switch>
    </Router>
    );
	
      }
    }
export default App;
