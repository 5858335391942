import React from 'react';
import Masonry from 'react-masonry-css';
import $ from "jquery";
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import {Row, Col } from 'reactstrap';
import Select from 'react-select';
import Modal_Popup from '../Modal-Popup';

import gridImg1 from '../../common/images/grid-img1.png';
import gridImg2 from '../../common/images/grid-img2.png';
import profileBanner from '../../common/images/profile-banner.jpg';
import ico_list from '../../common/images/ico_list.png';
import ico_document from '../../common/images/ico_document.png';
import ico_clipboard from '../../common/images/ico_clipboard.png';
import exeditor from '../../common/images/exeditor.jpg';
import profile from '../../common/images/profile.png';
import user from '../../common/images/user.png';
import map from '../../common/images/map.jpg';
import blockbox from '../../common/images/block-box.png';

class Index extends React.Component {
    constructor(props) {
        super(props);
        
        let uriparms_one = '';
        let uriparms_two = '';
        let pathname = props.location.pathname;
        pathname = pathname.split('/');

        uriparms_one = pathname[1];

        if(typeof pathname[2] !== 'undefined') {
         uriparms_two = pathname[2];
        }

		this.state = {
          modalReflection: false,
          uriparms_one:uriparms_one,
            uriparms_two:uriparms_two
		};
		
		this.toggleReflection = this.toggleReflection.bind(this);
        
       
      }
      
      toggleReflection() {
		//e.preventDefault();
		this.setState(prevState => ({
			modalReflection: !prevState.modalReflection
		}));
     }
      
     componentDidMount() {

        var moretext = "Read more";
        var lesstext = "Read less"; 
        var str = $(".txt p").text();
        var len = str.length;
        console.log(len);
        $(".readmore").click(function(){
            if($(this).hasClass("less")) {
                $(this).removeClass("less");
                $(this).parent().removeClass("active");
                $(this).html(moretext);
            } else {
                $(this).addClass("less");
                $(this).parent().addClass('active');
                $(this).html(lesstext);
            }
        });           


 }

render() { 

const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    850: 1
}

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

  return (
    <div>
    <Header {...this.state}/>
    <div className="banner-sec">
        <img src={profileBanner} alt="profile" />
        <div className="container">
            <div className="change-banner">
                    <Link to="" className="btn change-cover-btn">Change Cover</Link><br/>
                    <span className="banner-size">1360px X 310px</span>
            </div> 
        </div> 
    </div>
    <div className="container"> {/*----container-----*/}  
    <div className="content-section">  {/*----content-section-----*/}
    <div className="file-export">
        <Link to="" className="export-icon">Export</Link>
    </div>
    <div className="bg_white_box my-profile-sec">                
        <Link to="" className="edit-icon"></Link>
        <Row className="myprofile-row">
            <Col sm="3">  
                <div className="profile-pic">
                    <img src={profile} alt="my profile" />
                </div>
            </Col>
            <Col sm="9" className="profile-dtls">
                <h2 className="title1">My Profile</h2>
                <form class="profile-form">
                    <div className="form-group">
                        <label>Name</label>
                        <div className="input_field">
                            <input type="text" className="form-control" placeholder="John Tan" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Programme</label>
                        <div className="input_field">
                            <input type="text" className="form-control" placeholder="Post-Graduate Diploma in Education (July 2020)" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <div className="input_field">
                            <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                        </div>
                    </div>
                </form>  
            </Col>
        </Row>            
    </div>

    <div className="bg_white_box">  {/*----White Box-----*/}  
        <div className="heading-sec"> 
            <Row className="heading-top mrgn_btm">
                <Col sm="8">  
                    <h2 className="title1">My Teaching Philosophy</h2>
                </Col>
                <Col sm="4" className="right">
                <div className="input_field custom_select">
                        <Select />
                    </div>                       
                </Col>
            </Row>              
        </div>

        <div className="grid-list-row">  {/*----Grid Row-----*/}  
        <Masonry  breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">           
            <div className="grid-box">               
            <Link to="" className="close-icon">X</Link>
            <div className="post-type" >                    
                <div className="input_field custom_select">
                    <Select />
                </div> 
            </div>
            <div className="grid-img">  
                <img src={gridImg1} alt="" />
            </div>
            <div className="grid-body">
                <div className="grid-date">
                    <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                </div>
                <div className="grid-text">
                    <h2 className="title2">Title2</h2>
                    <div className="txt">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                        voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                        voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>

                        <Link to="" className="readmore">Read more</Link>
                    </div>
                </div>
                <div class="share-dtls">
                    <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                    <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                    <div className="like-cmnts">
                        <Link to="" className="like"></Link>
                        <Link to="" className="coments"><span className="count">4</span></Link>
                    </div>
                </div>
                <div className="tags">
                    <p>VER1, VER2, VER3 #teaching philosophy
                        <Link to="" className="readmore">...</Link>
                    </p>
                </div>
                <div className="review-comment">   
                    <div className="comment-list">
                        <span className="user-img"><img src={user} alt="" /></span>
                        <div className="comments-text">
                            <div className="post_name_date">
                                <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                            </div>
                            <p>Can you send me more informtion please</p>
                        </div>
                        <Link to="" class="close-icon">x</Link>
                    </div>                    
                    <div className="write-comment">
                        <div className="input_field">
                            <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                            <span className="user-img"><img src={user} alt="" /></span>
                        </div>
                    </div> 
                        
                    </div>
                </div>
            </div> 

            <div className="grid-box">               
            <Link to="" className="close-icon">X</Link>
            <div className="post-type" >                    
                <div className="input_field custom_select">
                    <Select />
                </div> 
            </div>
            <div className="grid-img">  
                <img src={gridImg1} alt="" />
            </div>
            <div className="grid-body">
                <div className="grid-date">
                    <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                </div>
                <div className="grid-text">
                    <h2 className="title2">Title2</h2>
                    <div className="txt">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                        voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                        
                        <Link to="" className="readmore">Read more</Link>
                    </div>
                </div>
                <div class="share-dtls">
                    <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                    <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                    <div className="like-cmnts">
                        <Link to="" className="like"></Link>
                        <Link to="" className="coments"><span className="count">4</span></Link>
                    </div>
                </div>
                <div className="tags">
                    <p>VER1, VER2, VER3 #teaching philosophy
                        <Link to="" className="readmore">...</Link>
                    </p>
                </div>
                <div className="review-comment">   
                    <div className="comment-list">
                        <span className="user-img"><img src={user} alt="" /></span>
                        <div className="comments-text">
                            <div className="post_name_date">
                                <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                            </div>
                            <p>Can you send me more informtion please</p>
                        </div>
                        <Link to="" class="close-icon">x</Link>
                    </div>                    
                    <div className="write-comment">
                        <div className="input_field">
                            <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                            <span className="user-img"><img src={user} alt="" /></span>
                        </div>
                    </div> 
                        
                    </div>
                </div>
            </div> 
            </Masonry>
        </div> {/*----Grid Row End-----*/}  
    </div> {/*----White Box End-----*/}  
    <div className="bg_white_box">  {/*----White Box-----*/}  
        <div className="heading-sec"> 
            <Row className="heading-top mrgn_btm">
                <Col sm="8">  
                    <h2 className="title1">My Learning Experiences</h2>
                </Col>
                <Col sm="4" className="right">
                <div className="input_field custom_select">
                        <Select />
                    </div>                       
                </Col>
            </Row>              
        </div>

        <div className="grid-list-row">  {/*----Grid Row-----*/}        
        <Masonry  breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">       
            <div className="grid-box">               
            <Link to="" className="close-icon">X</Link>
            <div className="post-type" >                    
                <div className="input_field custom_select">
                    <Select />
                </div> 
            </div>
            <div className="grid-img">  
                <img src={gridImg1} alt="" />
            </div>
            <div className="grid-body">
                <div className="grid-date">
                    <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                </div>
                <div className="grid-text">
                    <h2 className="title2">Title2</h2>
                    <div className="txt">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                        voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                        voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>

                        <Link to="" className="readmore">Read more</Link>
                    </div>
                </div>
                <div class="share-dtls">
                    <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                    <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                    <div className="like-cmnts">
                        <Link to="" className="like"></Link>
                        <Link to="" className="coments"><span className="count">4</span></Link>
                    </div>
                </div>
                <div className="tags">
                    <p>VER1, VER2, VER3 #teaching philosophy
                        <Link to="" className="readmore">...</Link>
                    </p>
                </div>
                <div className="review-comment">   
                    <div className="comment-list">
                        <span className="user-img"><img src={user} alt="" /></span>
                        <div className="comments-text">
                            <div className="post_name_date">
                                <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                            </div>
                            <p>Can you send me more informtion please</p>
                        </div>
                        <Link to="" class="close-icon">x</Link>
                    </div>                    
                    <div className="write-comment">
                        <div className="input_field">
                            <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                            <span className="user-img"><img src={user} alt="" /></span>
                        </div>
                    </div> 
                        
                    </div>
                </div>
            </div> 

            <div className="grid-box">               
            <Link to="" className="close-icon">X</Link>
            <div className="post-type" >                    
                <div className="input_field custom_select">
                    <Select />
                </div> 
            </div>
            <div className="grid-img">  
                <img src={gridImg1} alt="" />
            </div>
            <div className="grid-body">
                <div className="grid-date">
                    <p>Shared at - Nov 29 2018 at 9.49 AM</p>
                </div>
                <div className="grid-text">
                    <h2 className="title2">Title2</h2>
                    <div className="txt">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                        voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi temporibus praesentium neque, 
                        voluptatum quam quibusdam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                        
                        <Link to="" className="readmore">Read more</Link>
                    </div>
                </div>
                <div class="share-dtls">
                    <p className="share-with">Shared with : <Link to="">Jenifer, John and 10 Others</Link></p>
                    <div className="event"><span>SALT</span> <span>Event Name</span> <span>Value</span></div>                        
                    <div className="like-cmnts">
                        <Link to="" className="like"></Link>
                        <Link to="" className="coments"><span className="count">4</span></Link>
                    </div>
                </div>
                <div className="tags">
                    <p>VER1, VER2, VER3 #teaching philosophy
                        <Link to="" className="readmore">...</Link>
                    </p>
                </div>
                <div className="review-comment">   
                    <div className="comment-list">
                        <span className="user-img"><img src={user} alt="" /></span>
                        <div className="comments-text">
                            <div className="post_name_date">
                                <b className="username">Karthik Rangasamy ,</b> <span className="date">28-10-2019</span>
                            </div>
                            <p>Can you send me more informtion please</p>
                        </div>
                        <Link to="" class="close-icon">x</Link>
                    </div>                    
                    <div className="write-comment">
                        <div className="input_field">
                            <input type="text" className="form-control" placeholder="derrick@nie.edu.sg" />
                            <span className="user-img"><img src={user} alt="" /></span>
                        </div>
                    </div> 
                        
                    </div>
                </div>
            </div> 
            </Masonry> 
        </div> {/*----Grid Row End-----*/}  
    </div> {/*----White Box End-----*/}  


        <div className="bg_white_box visualization-sec">  {/*----White Box-----*/}  
        <div className="heading-sec"> 
            <Row className="heading-top mrgn_btm">
                <Col sm="6">  
                    <h2 className="title1">Visualization</h2>
                </Col>
                <Col sm="6" className="right">
                <Link to="" onClick={this.toggleReflection} className="btn btn_blue reflex_btn" title="Button">Write reflection</Link>                         
                </Col>
            </Row>              
        </div>
        <div className="block-grid">
            <img src={blockbox} alt="map" />
        </div>
        </div> {/*----White Box End-----*/}  


        <div className="bg_white_box">  {/*----White Box-----*/}  
        <div className="heading-sec"> 
            <Row className="heading-top mrgn_btm mymap-sec">
                <Col sm="3">  
                    <h2 className="title1">My Map</h2>
                </Col>
                <Col sm="9" className="right">
                <form className="form_sec">
                    <div className="form-group">
                     <Row>
                        <Col> 
                        <div className="input_field custom_select">
                            <Select />
                        </div>
                        </Col>
                        <Col> 
                        <div className="input_field custom_select">
                            <Select />
                        </div>
                        </Col>
                        <Col> 
                        <div className="input_field custom_select">
                            <Select />
                        </div>
                        </Col>              
                     </Row>
                     </div>
                   </form>                        
                </Col>
            </Row>              
        </div>
        <div className="mymap-grid">
            <img src={map} alt="map" />
        </div>
        </div> {/*----White Box End-----*/}  


        {/* Popup Reflection */}
        <Modal_Popup modal={this.state.modalReflection} toggle={this.toggleReflection} className="popup_sec popup_reflection">
        <div class="popup_in">
            <h3 className="title2">Write Your Reflection</h3>
            <form className="form_sec">
                <div className="keytag_list share_keytag_list">
                    <label>Share With:</label>
                    <div className="keytag_item">
                        <span>VER1</span>
                        <i>x</i>
                    </div>
                    <div className="keytag_item">
                        <span>VER2</span>
                        <i>x</i>
                    </div>
                    <div className="keytag_item">
                        <span>VER3 #teaching philosophy</span>
                        <i>x</i>
                    </div>                                                          
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-6 col-12">
                            <div className="input_field input_greyfield input_field_igroup">
                                <div className="custom_select">
                                    <i className="input_filed_ico">
                                        <img src={ico_list} alt="" />
                                    </i>
                                    <Select
                                        //value={this.state.productSelectedOption }
                                        searchable='true'
                                        //onChange={this.handleChangeone}
                                        placeholder='Courses'
                                        options={options}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12">
                            <div className="input_field input_greyfield">
                                <input type="text" className="form-control" value="75 Ayer Rajah Crescent, Singapore 139953" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-6 col-12">
                            <div className="input_field input_greyfield input_field_igroup">
                                <div className="custom_select">
                                    <i className="input_filed_ico">
                                        <img src={ico_document} alt="" />
                                    </i>
                                    <Select
                                        //value={this.state.productSelectedOption }
                                        searchable='true'
                                        //onChange={this.handleChangeone}
                                        placeholder='Values'
                                        options={options}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12">
                            <div className="row">
                                <div className="col-sm-6 col-12">
                                    <div className="input_field input_greyfield">
                                        <div className="custom_select">
                                            <Select 
                                                searchable='true'
                                                placeholder='Share at'
                                                options={options} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="input_field input_greyfield">
                                        <div className="custom_select">
                                            <Select 
                                                searchable='true'
                                                placeholder='Share with '
                                                options={options} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-6 col-12">
                            <div className="input_field input_greyfield input_field_igroup">
                                <div className="custom_select">
                                    <i className="input_filed_ico">
                                        <img src={ico_clipboard} alt="" />
                                    </i>
                                    <Select
                                        //value={this.state.productSelectedOption }
                                        searchable='true'
                                        //onChange={this.handleChangeone}
                                        placeholder='Events'
                                        options={options}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12">
                            <div className="input_field input_greyfield hashtag_field">
                                <input type="text" className="form-control" value="VER1, VER2, VER3 #teaching philosophy" />
                            </div>
                            <div className="keytag_list">
                                <div className="keytag_item">
                                    <span>VER1</span>
                                    <i>x</i>
                                </div>
                                <div className="keytag_item">
                                    <span>VER2</span>
                                    <i>x</i>
                                </div>
                                <div className="keytag_item">
                                    <span>VER3 #teaching philosophy</span>
                                    <i>x</i>
                                </div>                                                          
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ktxteditor_wrapper">
                    <div className="form-group from_refttimg_group">
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12">
                                <div className="input_field">
                                    <input type="text" className="form-control" placeholder="Enter Title of the the reflection..." />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <div className="input_field inputref_imgfield">
                                    <div className="row">
                                        <div className="col-md-8 col-7">
                                            <label>Upload featured image</label>
                                        </div>
                                        <div className="col-md-4 col-5">
                                            <div className="choose_file">
                                                <input type="file" className="form-control" />
                                                <span>Browse</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
                    <div className="ktxteditor_sec">
                        <img src={exeditor} alt="" />
                    </div>                         
                </div>
                <div className="btn_sec text-right">
                    <button type="submit" className="btn btn_blue btn_minwid">SAVE</button>
                </div>
            </form>
        </div>
        </Modal_Popup>

        </div>  {/*----content-section end-----*/}
        </div>  {/*------Container End------*/}
        </div>
  );


  }
}

export default Index;
