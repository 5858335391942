import React from 'react';
import Select from 'react-select';
import Modal_Popup from './Modal-Popup';
import Header from './Layout/Header';
import ico_list from '../common/images/ico_list.png';
import ico_document from '../common/images/ico_document.png';
import ico_clipboard from '../common/images/ico_clipboard.png';
import exeditor from '../common/images/exeditor.jpg';


class Style extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalPopup: false
        }
        this.togglePopup = this.togglePopup.bind(this);
    }
    togglePopup() {
        //e.preventDefault();
        this.setState(prevState => ({
            modalPopup: !prevState.modalPopup
        }));
    }
    render() {

        const options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' },
        ];


        return (

            <div>
                <Header />
                <div className="container">
                    <h2 className="title1">Title1</h2>
                    <h2 className="title2">Title2</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make</p>
                    <a href="javascript:void(0);" className="btn btn_blue btn_sm" title="Button">Button</a><br /><br />
                    <a href="javascript:void(0);" className="btn btn_blue" title="Button">Button</a><br /><br />
                    <a href="javascript:void(0);" className="btn btn_blue btn_minwid" title="Button">Button</a><br /><br />
                    <a href="javascript:void(0);" className="btn btn_grey btn_sm" title="Button">Button</a><br /><br />
                    <a href="javascript:void(0);" className="btn btn_grey" title="Button">Button</a><br /><br />
                    <a href="javascript:void(0);" className="btn btn_grey btn_minwid" title="Button">Button</a><br /><br />
                    <a href="javascript:void(0);" className="btn btn_lightgrey btn_sm" title="Button">Button</a><br /><br />
                    <a href="javascript:void(0);" className="btn btn_lightgrey" title="Button">Button</a><br /><br />
                    <a href="javascript:void(0);" className="btn btn_lightgrey btn_minwid" title="Button">Button</a><br /><br />


                    <h3 className="title2">Form:</h3>
                    <form className="form_sec">
                        <div className="form-group">
                            <div className="row">
                                <div className="col">
                                    <div className="input_field">
                                        <input type="text" placeholder="Your Name" className="form-control" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input_field">
                                        <input type="text" placeholder="Email Address" className="form-control" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input_field">
                                        <input type="text" placeholder="Phone Number" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input_field">
                                <textarea placeholder="How can we help?" className="form-control"></textarea>
                            </div>
                        </div>
                        <div className="form-group text-center"><button className="btn btn_blue btn_minwid">Send Enquiry</button></div>
                    </form>


                    <h3 className="title2">Form:</h3>
                    <form className="form_sec">
                        <div className="form-group">
                            <div className="row">
                                <div className="col">
                                    <label>Label</label>
                                    <div className="input_field">
                                        <input type="text" className="form-control" />
                                    </div>
                                    <small className="text-muted form-text">We will never share your email with anyone else.</small>
                                </div>
                                <div className="col">
                                    <label>Label</label>
                                    <div className="input_field">
                                        <input type="text" className="form-control" />
                                    </div>
                                    <small className="text-muted form-text">We will never share your email with anyone else.</small>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col">
                                    <label>Label</label>
                                    <div className="input_field">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col">
                                    <label>Label</label>
                                    <div className="input_field">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col">
                                    <label>Label</label>
                                    <div className="input_field">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input_field">
                                <div className="custom_select">
                                    <Select />
                                </div>
                            </div>
                        </div>


                        <div className="form-group">
                            <div className="input_field">
                                <div className="custom_select">
                                    <Select
                                        //value={this.state.productSelectedOption }
                                        searchable='true'
                                        //onChange={this.handleChangeone}
                                        placeholder='Multiple Select'
                                        options={options}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="form-group">
                            <div className="input_field">
                                <div className="custom_select">
                                    <Select
                                        //value={this.state.productSelectedOption }
                                        searchable='true'
                                        //onChange={this.handleChangeone}
                                        placeholder='Single Select'
                                        options={options}
                                    //isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="form-group">
                            <div className="custom_checkbox">
                                <input type="checkbox" /> <span>Checkbox</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="custom_radio">
                                <input type="radio" defaultChecked /> <span>Radio</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <button className="btn btn_blue btn_minwid">Button</button>
                        </div>
                    </form>
                    <h3 className="title2"> Alert</h3>
                    <div className="alert alert-success">
                        This is a alert—check it out!
            <button type="button" className="close"><span>×</span></button>
                    </div>
                    <div className="alert alert-danger">
                        This is a alert—check it out!
            <button type="button" className="close"><span>×</span></button>
                    </div>
                    <div className="row">
                        <div className="col">testing</div>
                        <div className="col">testing</div>
                        <div className="col">testing</div>
                    </div>

                    <div className="row">
                        <h3>Modal Popup</h3>
                        <div>
                            <a href="javascript:void(0);" onClick={this.togglePopup} className="btn btn_blue reflex_btn" title="Button">Modal Popup</a>
                        </div>
                    </div>

                    <Modal_Popup modal={this.state.modalPopup} toggle={this.togglePopup} className="popup_sec popup_reflection">
                        <div class="popup_in">
                            <h3 className="title2">Write Your Reflection</h3>
                            <form className="form_sec">
                                <div className="keytag_list share_keytag_list">
                                    <label>Share With:</label>
                                    <div className="keytag_item">
                                        <span>VER1</span>
                                        <i>x</i>
                                    </div>
                                    <div className="keytag_item">
                                        <span>VER2</span>
                                        <i>x</i>
                                    </div>
                                    <div className="keytag_item">
                                        <span>VER3 #teaching philosophy</span>
                                        <i>x</i>
                                    </div>                                                          
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-6 col-12">
                                            <div className="input_field input_greyfield input_field_igroup">
                                                <div className="custom_select">
                                                    <i className="input_filed_ico">
                                                        <img src={ico_list} alt="" />
                                                    </i>
                                                    <Select
                                                        //value={this.state.productSelectedOption }
                                                        searchable='true'
                                                        //onChange={this.handleChangeone}
                                                        placeholder='Courses'
                                                        options={options}
                                                        isMulti={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <div className="input_field input_greyfield">
                                                <input type="text" className="form-control" value="75 Ayer Rajah Crescent, Singapore 139953" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-6 col-12">
                                            <div className="input_field input_greyfield input_field_igroup">
                                                <div className="custom_select">
                                                    <i className="input_filed_ico">
                                                        <img src={ico_document} alt="" />
                                                    </i>
                                                    <Select
                                                        //value={this.state.productSelectedOption }
                                                        searchable='true'
                                                        //onChange={this.handleChangeone}
                                                        placeholder='Values'
                                                        options={options}
                                                        isMulti={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <div className="row">
                                                <div className="col-sm-6 col-12">
                                                    <div className="input_field input_greyfield">
                                                        <div className="custom_select">
                                                            <Select 
                                                                searchable='true'
                                                                placeholder='Share at'
                                                                options={options} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-12">
                                                    <div className="input_field input_greyfield">
                                                        <div className="custom_select">
                                                            <Select 
                                                                searchable='true'
                                                                placeholder='Share with '
                                                                options={options} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-6 col-12">
                                            <div className="input_field input_greyfield input_field_igroup">
                                                <div className="custom_select">
                                                    <i className="input_filed_ico">
                                                        <img src={ico_clipboard} alt="" />
                                                    </i>
                                                    <Select
                                                        //value={this.state.productSelectedOption }
                                                        searchable='true'
                                                        //onChange={this.handleChangeone}
                                                        placeholder='Events'
                                                        options={options}
                                                        isMulti={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <div className="input_field input_greyfield hashtag_field">
                                                <input type="text" className="form-control" value="VER1, VER2, VER3 #teaching philosophy" />
                                            </div>
                                            <div className="keytag_list">
                                                <div className="keytag_item">
                                                    <span>VER1</span>
                                                    <i>x</i>
                                                </div>
                                                <div className="keytag_item">
                                                    <span>VER2</span>
                                                    <i>x</i>
                                                </div>
                                                <div className="keytag_item">
                                                    <span>VER3 #teaching philosophy</span>
                                                    <i>x</i>
                                                </div>                                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ktxteditor_wrapper">
                                    <div className="form-group from_refttimg_group">
                                        <div className="row">
                                            <div className="col-md-8 col-sm-6 col-12">
                                                <div className="input_field">
                                                    <input type="text" className="form-control" placeholder="Enter Title of the the reflection..." />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-12">
                                                <div className="input_field inputref_imgfield">
                                                    <div className="row">
                                                        <div className="col-md-8 col-7">
                                                            <label>Upload featured image</label>
                                                        </div>
                                                        <div className="col-md-4 col-5">
                                                            <div className="choose_file">
                                                                <input type="file" className="form-control" />
                                                                <span>Browse</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>       
                                    <div className="ktxteditor_sec">
                                        <img src={exeditor} alt="" />
                                    </div>                         
                                </div>
                                <div className="btn_sec text-right">
                                    <button type="submit" className="btn btn_blue btn_minwid">SAVE</button>
                                </div>
                            </form>
                        </div>
                    </Modal_Popup>



                </div>
            </div>
        );
    }
}

export default Style;
