const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_NEWS':
      return { ...state, loading: true };
    case 'SET_NEWS':     
      return { ...state, news: action.json[0], loading: false }
    case 'GET_LOGIN':
      return { ...state, loading: true };
    case 'SET_LOGIN':
      return { ...state, userdata: action.json, loading: false }
    default:
      return state;
  }
};

export default reducer;