import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GET_LOGIN } from '../../actions'


import cookie from 'react-cookies';
import { clientId } from '../Helpers/Config';
import GoogleLogin from 'react-google-login';

class Index extends React.Component {
  constructor(props) {
      super(props);
      if(typeof cookie.load('userDetails')!=='undefined' && cookie.load('userDetails')!=='') {
        const{history} = this.props;
        history.push("/");
      }
      this.state = {
        clientId:clientId,
        errormsg:'',
        userDetails:'',
      };
      

  }

  componentDidMount() {
  
  }
  componentWillReceiveProps(nextProps) {
    if(this.state.userdata!==nextProps.userdata) {
      if(nextProps.userdata.status==="success") {
          cookie.save("user_id", nextProps.userdata.result_set.userID);
          cookie.save("userDetails", nextProps.userdata.result_set);
          const{history} = this.props;
          history.push("/");
      }
      else {
        this.setState({errormsg:nextProps.userdata.message});
        setTimeout(() => {
          //this.setState({errormsg:''});
        }, 5000);
      }

    }
  }
  render() {
    const responseGoogle = (response,) => {
      if(typeof response.profileObj!='undefined') {
        var user_data = {email:response.profileObj.email, name : response.profileObj.name, profile_image : response.profileObj.imageUrl, googleId: response.profileObj.googleId };
        this.props.getLogin(user_data);  
      }
      else {
        this.setState({errormsg:'Somthing is wrong Try agian'});
        setTimeout(() => {
         // this.setState({errormsg:''});
        }, 5000);
      }
    }
    return (
      <div>
        <div className="login_sec">
          <div className="login_block">                       
              <h1 className="title2">Login to PPI</h1>
              <div className="login_btnsec">
                  <a href="https://www.google.com/a/g.nie.edu.sg/ServiceLogin?service=jotspot&passive=1209600&continue=https://sites.google.com/a/g.nie.edu.sg/values-based-education/&ul=1" className="btn btn_lightgrey btn_minwid" title="Go Back">Go Back</a>
                  <div className="btn btn_blue  btn_minwid">
                    <GoogleLogin
                          clientId={this.state.clientId}
                          buttonText="Login"
                          className="glogin_btn"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={'single_host_origin'}
                        />
                  </div>
                  <div className="alert alert-danger">{this.state.errormsg}</div>
              </div>
          </div>
        </div>
      </div>
    );

  }

}
const mapDispatchToProps = (dispatch) => {
  return {
    getLogin: (profileObj) => {
      dispatch({ type: GET_LOGIN, profileObj:profileObj });
    },
  }
}

const mapStateToProps = (state) => { 
  return {
   userdata: state.userdata
  }
}

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};
  
Index = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Index);

export default withRouter(Index);
