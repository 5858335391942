import React from 'react';
import Button from './Button';
import NewsItem from './Loading'
import Loading from './NewsItem'


let App = () => (
  <div>
    <Button />
    <Loading />
    <NewsItem />
  </div>
);


export default App;