import React from 'react';

import {  Link} from "react-router-dom";

import { Scrollbars } from 'react-custom-scrollbars';
import logo from '../../common/images/logo.png';
import menu_ico from '../../common/images/menu.png';

import hmenu_book from '../../common/images/hmenu_book.png';

import hmenu_book_over from '../../common/images/hmenu_book_over.png';
import hmenu_envolope from '../../common/images/hmenu_envolope.png';
import hmenu_envolope_over from '../../common/images/hmenu_envolope_over.png';
import hmenu_grid from '../../common/images/hmenu_grid.png';
import hmenu_grid_over from '../../common/images/hmenu_grid_over.png';
import hmenu_logout from '../../common/images/hmenu_logout.png';
import hmenu_logout_over from '../../common/images/hmenu_logout_over.png';
import hmenu_questions from '../../common/images/hmenu_questions.png';
import hmenu_questions_over from '../../common/images/hmenu_questions_over.png';
import hmenu_user from '../../common/images/hmenu_user.png';
import hmenu_user_over from '../../common/images/hmenu_user_over.png';
import hmenu_loc from '../../common/images/hmenu_loc.png';
import hmenu_loc_over from '../../common/images/hmenu_loc_over.png';
import profile1 from '../../common/images/profile1.png';
import $ from "jquery";



class Header extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            sidemenu_open: false,
            modalPopup: false,
            uriparms_one:props.uriparms_one,
            uriparms_two:props.uriparms_two
        }
        $('.sidemenu_list li').removeClass('active');
    }
    handleAddclass() {
        this.setState(prevState => ({
            sidemenu_open: !prevState.sidemenu_open
        }));

    }

    componentDidMount() {
        $(".nav_trigger").click(function(){
            $(".hsubmenu_list").slideUp();
        });
        $(".hsubmenu_arrow").click(function(){
            $(this).parent().next(".hsubmenu_list").toggleClass("active").slideToggle();
        });

    }

    componentDidUpdate(prevProps) {

        // Typical usage (don't forget to compare props):
        if (this.props.uriparms_one !== prevProps.uriparms_one ) {
         this.setState({ uriparms_one:this.props.uriparms_one})
        }

        if (this.props.uriparms_two !== prevProps.uriparms_two) {
            this.setState({ uriparms_two:this.props.uriparms_two})
         }
    }

    render() {
     
        return (

            <div>
                <header className="top_head">
                    <div className="tophead_in">
                        <div className="container">
                            <div className="row htrow">
                                <div className="col-md-8 col-12 logo_col">
                                    <Link to="/" className="logo">
                                        <img src={logo} alt="" />
                                    </Link>
                                </div>
                               {/*} <div className="col-md-4 col-8 hsearch_sec">
                                    <div className="hsearch_bx">
                                        <button type="submit"></button>
                                        <input type="text" className="form-control" placeholder="Search for posts" />
                                    </div>
                                </div>
                                {*/}
                            </div>
                        </div>
                    </div>
                </header>
                <div className={this.state.sidemenu_open == true ? 'side_bar sidemenu_open' : 'side_bar'}>

                    <div className="sidebar-main"></div>
                    <div className="sidebar_banspace">
                    <Link to="/profile" className="sidebar_profile_link" title="Profile">
                            <img src={profile1} alt="" />
                        </Link>
                    </div>
                    <div className="sidebar_menu">

                        <div className="menu_btn">
                            <Link to="#" className="nav_trigger" onClick={this.handleAddclass.bind(this)}>
                                <img src={menu_ico} alt="" title="" />
                            </Link>
                        </div>
                        <div className="smcustom_scroll">
                            <Scrollbars className="smcustom_scroll_in">
                                <ul className="sidemenu_list">
                                    <li className={(this.state.uriparms_one === 'profile')?"active":""}>
                                      <Link to="/profile" title="Profile">
                                            <span className="img_icon">
                                                <img src={hmenu_user} alt=""/>
                                                <img className="green-image" src={hmenu_user_over} alt="" />
                                            </span>
                                            <span className="text_img">Profile</span>
                                        </Link>
                                    </li>
                                    <li className={(this.state.uriparms_one === 'inbox')?"active":""}>
                                    <Link to="/inbox" title="Inbox">
                                            <span className="img_icon">
                                                <img src={hmenu_envolope} alt="" />
                                                <img className="green-image" src={hmenu_envolope_over} alt="" />
                                                <i className="inbox_count">3</i>
                                            </span>
                                            <span className="text_img">Inbox</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <a href="#" title="VBE">
                                            <span className="img_icon">
                                                <img src={hmenu_loc} alt=""/>
                                                <img className="green-image" src={hmenu_loc_over} alt="" />
                                            </span>
                                            <span className="text_img">VBE</span>
                                        </a>
                                    </li>
                                    <li className={(this.state.uriparms_one === 'artefacts')?"active":""}>
                                    <Link to="/artefacts" title="Artefacts">
                                            <span className="img_icon">
                                                <img src={hmenu_loc} alt=""/>
                                                <img className="green-image" src={hmenu_loc_over} alt="" />
                                            </span>
                                            <span className="text_img">Artefacts</span>
                                        </Link>
                                    </li>
                                    <li className={(this.state.uriparms_one === 'reflection')?"hsubmenu active":"hsubmenu"}>
                                        <Link to="/reflection" title="Reflections">
                                            <span className="img_icon">
                                                <img src={hmenu_book} alt=""/>
                                                <img className="green-image" src={hmenu_book_over} alt="" />
                                            </span>
                                            <span className="text_img">Reflections</span>
                                            <i className="hsubmenu_arrow"></i>
                                        </Link>
                                        <ul className="hsubmenu_list">
                                            <li className={(this.state.uriparms_one === 'reflection' && (this.state.uriparms_two === 'personal' || this.state.uriparms_two === ''))?"active":""}>
                                            <Link to="/reflection/personal" title="Personal">Personal</Link>
                                            </li >
                                            <li className={(this.state.uriparms_one === 'reflection' && this.state.uriparms_two === 'community')?"active":""}>
                                                <Link to="/reflection/community"  title="Community">Community</Link>
                                            </li>
                                            <li className={(this.state.uriparms_one === 'reflection' && this.state.uriparms_two === 'shared')?"active":""}>
                                                <Link to="/reflection/shared" title="Shared With Me">Shared With Me</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="">
                                    <a href="https://sites.google.com/g.nie.edu.sg/vbecoursekit/home" target="_blank" title="Help">
                                            <span className="img_icon">
                                                <img src={hmenu_questions} alt=""/>
                                                <img className="green-image" src={hmenu_questions_over} alt="" />
                                            </span>
                                            <span className="text_img">Help</span>
                                        </a>
                                    </li>
                                    <li className={(this.state.uriparms_one === 'showcase')?"active":""}>
                                    <Link to="/showcase" title="Showcase">
                                            <span className="img_icon">
                                                <img src={hmenu_grid} alt=""/>
                                                <img className="green-image" src={hmenu_grid_over} alt="" />
                                            </span>
                                            <span className="text_img">Showcase</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/logout" title="Logout">
                                            <span className="img_icon">
                                                <img src={hmenu_logout} alt=""/>
                                                <img className="green-image" src={hmenu_logout_over} alt="" />
                                            </span>
                                            <span className="text_img">Logout</span>
                                        </Link>
                                    </li>
                                </ul>
                            </Scrollbars>
                        </div>

                    </div></div>
            </div>
        );
    }
}

export default Header;
