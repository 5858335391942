import React from 'react'
import { render } from 'react-dom'

import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import reducer from './reducers'

import rootSaga from './sagas'
/* import css files */
import './index.css'
import './common/css/style.scss'
import './common/css/responsive.scss'

import App from './App'

import * as serviceWorker from './serviceWorker'

/* Create Store */
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducer,
  applyMiddleware(sagaMiddleware, logger),
)

sagaMiddleware.run(rootSaga)


render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
